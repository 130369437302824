@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  src: url('/public/fonts/LibreBaskerville-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  font-style: italic;
  src: url('/public/fonts/LibreBaskerville-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Arsenal';
  font-weight: 400;
  src: url('/public/fonts/Arsenal-Regular.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}
.graph-title {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.target-value {
  text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);
}
.container-shadow {
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
}

label {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

input {
  font-family:
    Libre Baskerville,
    ui-monospace;
  text-align: center;
}
select {
  padding-right: 5px;
}

tr > td {
  padding-left: 1em;
}
tr > td > input {
  font-size: smaller;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: right;
  padding-right: 1em;
  padding-left: 1em;
}

nav li {
  border-bottom: 2px solid #42644c;
  text-align: center;
}

.coNames > div {
  margin-bottom: 1em;
}
.coNames {
  border-right: 3px;
}

.smallBar {
  padding-top: 20px;
}

.buttonImage {
  text-transform: none;
  font-family: Baskervville;
  letter-spacing: normal;
  font-style: normal;
  font-weight: 400;
  white-space: pre;
  font-size: 15px;
  text-decoration: none solid rgb(247, 255, 240);
  direction: ltr;
  fill: rgb(247, 255, 240);
  fill-opacity: 1;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
